import { NgModule, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { LocalStorageModule } from 'angular-2-local-storage';
import { NgxUiLoaderModule, NgxUiLoaderConfig, POSITION, NgxUiLoaderRouterModule  } from 'ngx-ui-loader';
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
import { NgxCaptchaModule } from 'ngx-captcha'; //For captcha
import { UserIdleModule } from 'angular-user-idle';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompanyloginComponent, TrackCapsDirective } from './adminpanel/companylogin/companylogin.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';



const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#000000",
  overlayColor: "rgba(173,175,226,0.8)",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 60,
  fgsPosition: "center-center",
  fgsSize: 50,
  blur: 10,
  bgsType: "ball-spin-clockwise", // background spinner type
  fgsType: "ball-spin-clockwise", // foreground spinner type
  hasProgressBar: false,
  fgsColor: "#000000",
};

@NgModule({
  declarations: [
    AppComponent,
    CompanyloginComponent,
    TrackCapsDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    LocalStorageModule.forRoot({
      storageType: 'localStorage',
      prefix: ''
    }),
    ToastrModule.forRoot(
     
    ),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgbModule.forRoot(),
    NgbDropdownModule,
    AngularEditorModule,
    DeviceDetectorModule.forRoot(),
    FullCalendarModule, // for FullCalendar!,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    DialogsModule,
    ButtonsModule,
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
    // and `ping` is 120 (2 minutes).
    //UserIdleModule.forRoot({ idle: 10, timeout: 5, ping: 3 })
    UserIdleModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
