
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyloginComponent } from './adminpanel/companylogin/companylogin.component';

const routes: Routes = [
  { path: '', loadChildren: './landingpage/landingpage.module#LandingpageModule' },
  { path: 'super_admin', loadChildren: './superadmin/superadmin.module#SuperadminModule' },
  { path: 'Login', component: CompanyloginComponent },
  { path: ':cname', loadChildren: './adminpanel/admin.module#AdminModule'}
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
