import { Component, OnInit, ViewChild, ElementRef, Directive, Output, HostListener, EventEmitter, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import Swal from 'sweetalert2';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserIdleService, UserIdleConfig } from 'angular-user-idle';
declare var $: any;

@Component({
  selector: 'app-companylogin',
  templateUrl: './companylogin.component.html',
  styleUrls: ['./companylogin.component.css', '../../../assets/css/plugins/bootstrap.min.css', '../../../assets/css/font-awesome.min.css', '../../../assets/css/adminloginstyle.css', '../../../assets/css/main_login.css', '../../../assets/css/util.css'],
  animations: [
    trigger('shakeit', [
      state('shakestart', style({
        transform: 'scale(1)',
      })),
      state('shakeend', style({
        transform: 'scale(1)',
      })),
      transition('shakestart => shakeend', animate('1000ms ease-in', keyframes([
        style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.1 }),
        style({ transform: 'translate3d(4px, 0, 0)', offset: 0.2 }),
        style({ transform: 'translate3d(-6px, 0, 0)', offset: 0.3 }),
        style({ transform: 'translate3d(6px, 0, 0)', offset: 0.4 }),
        style({ transform: 'translate3d(-6px, 0, 0)', offset: 0.5 }),
        style({ transform: 'translate3d(6px, 0, 0)', offset: 0.6 }),
        style({ transform: 'translate3d(-6px, 0, 0)', offset: 0.7 }),
        style({ transform: 'translate3d(4px, 0, 0)', offset: 0.8 }),
        style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.9 }),
      ]))),
    ])
  ]
})


///** companylogin component*/
export class CompanyloginComponent implements OnInit {
  capsOn: any;

  aFormGroup: FormGroup;
  Showcompanies: boolean = false;
  //aFormGroup = new FormGroup({
  //    recaptcha:new FormControl()
  //})

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  deviceInfo: any;
  otpstatustext: string = "";
  otpstatus: boolean = false;
  logintoken: string;
  public username: string;
  public password: string;
  public getlogindata: any;
  public resetemailid: string;
  public resetusername: string;
  otpdetail: string = "";
  successfullogin: any;

  usernamevalid: boolean = false;
  passwordvalid: boolean = false;
  emailvalid: boolean = false;
  resetuservalid: boolean = false;
  Resetbutton: boolean = false;
  Showcompany: boolean = false;
  //rusername: boolean = false;
  resetnewpwd: any = "";
  resetcnfpwd: any = "";
  states = {};
  CompanyId: any;
  validateotp: boolean = false;


  newvalid: boolean = false;
  companydetails: Array<any> = [];
  companyid: any;

  countDown;
  counter = 30 * 60;
  tick = 1000;
  sessionconfig: UserIdleConfig = {};

  @ViewChild('closeapprovedModal') closeapprovedModal: ElementRef; // Needed for close modal poup fron typescript


  /** companylogin ctor */
  constructor(private http: HttpClient, private router: Router, private localstorage: LocalStorageService, private ngxService: NgxUiLoaderService, private toastr: ToastrService, private deviceService: DeviceDetectorService, private activeroute: ActivatedRoute, private formBuilder: FormBuilder, private userIdle: UserIdleService) {

  }
  ngOnInit() {
    

    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId

    this.loadScript('../../../assets/js/plugins/jquery.min.js');
    this.loadScript('../../../assets/js/plugins/bootstrap.min.js');

    // Stop the foreground loading after 2s
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 2000);


    this.activeroute.queryParams.subscribe(params => {
      if (params.id != undefined) {
        this.CompanyId = parseInt(atob(params.id));
      }

    })
    this.epicFunction();
    this.companyid = "0";
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
  }
  epicFunction() {
    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    //console.log(this.deviceInfo);
    //console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    //console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    //console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  shakeEnd(stateVar: string, event) {
    this.states[stateVar] = 'shakeend';
  }
  //

  //validation on change function
  usernamechange(username) {
    if (username == undefined || username == "") {
      this.usernamevalid = true;
    }
    else {
      this.usernamevalid = false;
    }
  }

  getusercompanies(username) {
    
    if (username == undefined || username == "") {
      this.usernamevalid = true;
    }
    else {
      this.usernamevalid = false;
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      let options = {
        headers: headers
      }
      var logintype = "company";
      var type = 'GetallCompanies';
      this.http.get('smartboardApi/Getlogincompanies?logintype=' + logintype + '&username=' + username, options).subscribe(
        (res) => {
          this.getlogindata = res;
          
          if (this.getlogindata.status == true) {
            this.Showcompanies = true;
            this.companydetails = this.getlogindata.data;
            this.companyid = "0";
            if (this.companydetails.length == 1) {
              this.companyid = this.companydetails[0].CompanyId
            }
          }
          else {
            Swal.fire({
              position: 'center',
              text: this.getlogindata.message,
              showConfirmButton: true,
              timer: 3000
            })
            this.usernamevalid = true;
            this.companydetails = [];
            //this.Showcompany = false;
          }

        },
        (err) => {
          this.companydetails = [];
          this.Showcompany = false;
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
          })
          Toast.fire({
            type: 'success',
            title: err.statusText
          })
        }
      );
    }
  }

  passwordchange(password) {

    if (password == undefined || password == "") {
      this.passwordvalid = true;
    }
    else {
      this.passwordvalid = false;
    }
  }

  emailidchange(email) {
    if (email == undefined || email == "") {
      this.emailvalid = true;
    }
    else {
      var emailpattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (emailpattern.test(email) == false) {
        this.emailvalid = true;
      }
      else {
        this.emailvalid = false;
      }
    }
  }
  resetusernamechange(uname) {
    if (uname == undefined || uname == "") {
      this.resetuservalid = true;
    }
    else {
      this.resetuservalid = false;
    }
  }
  //End Validation


  loginclick() {
    
    if (this.username == undefined || this.username == "" || this.password == undefined || this.password == "") {
      this.usernamevalid = true;
      this.passwordvalid = true;
      this.toastr.warning("Please enter user email and password to login.", "", { timeOut: 2000 });
      this.states['state1'] = (this.states['state1'] === 'shakestart' ? 'shakeend' : 'shakestart');
      return;
    }


    if (this.username == undefined && this.password == undefined) {
      this.usernamevalid = true;
      this.passwordvalid = true;
      return;
    }
    if (this.Showcompanies == false) {
      this.usernamevalid = true;
      this.toastr.warning("Invalid Email Id", "", { timeOut: 2000 });
      return
    }
    if (this.username == undefined || this.username == "") {
      this.usernamevalid = true;
      return;
    }
    if (this.password == undefined || this.password == "") {
      this.passwordvalid = true;
      return;
    }
    if (isDevMode() == false) {
      if (this.successfullogin == undefined || this.successfullogin == "") {
        this.toastr.error("Please verify that you are not a robot.", "", { timeOut: 2000 });
        return;
      }
    }


    if (this.companyid == undefined || this.companyid == "0") {
      this.toastr.warning("Select a company.", "", { timeOut: 2000 });
      return;
    }
    //else {
    //  this.companyid = 0;
    //}


    this.ngxService.start();
    var Data = {
      "username": this.username,//insert data from Frontend
      "password": this.password,
      "logintype": "company",
      "companyId": this.companyid,
      "Source": "WebApp",
      "Browsername": this.deviceInfo.browser,
      "os": this.deviceInfo.os_version
    };

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }
    let body = JSON.stringify(Data);//Convert frontend Data into Json
    this.http.post('smartboardApi/login', body, options).// API/Login iS the root Of Login.cs Page
      subscribe(
        (data) => {
          this.ngxService.stop();
          //console.log(data);
          
          this.getlogindata = data;
          if (this.getlogindata.status == true) {
            this.localstorage.set('companyname', this.getlogindata.data.CompanyName);
            this.localstorage.set('usertoken', this.getlogindata.data.token);
            this.localstorage.set('companyid', this.getlogindata.data.CompanyID);
            this.localstorage.set('Usertype', this.getlogindata.data.UserType);
            this.localstorage.set('Logid', this.getlogindata.data.LoginLogID);
            var cname = this.getlogindata.data.CompanyName;
            if (this.getlogindata.data.FirstLogin == "Yes" || this.getlogindata.data.PwdExpired == 1) {
              this.router.navigate([cname, 'Changepassword']);
            }
            else {

              this.router.navigate([cname, 'Meetingdashboard']);

            }


            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000
            })
            Toast.fire({
              type: 'success',
              title: this.getlogindata.message
            })
          }
          else {
            
            if (this.getlogindata.data != undefined) {
              this.localstorage.set('companyid', this.getlogindata.data.CompanyID);
              this.localstorage.set('Usertype', this.getlogindata.data.UserType);
              this.localstorage.set('companyname', this.getlogindata.data.CompanyName);
              this.localstorage.set('userid', this.getlogindata.data.Userid);
              if (this.getlogindata.data.UserType == "Admin") {
                var cname = this.getlogindata.data.CompanyName;
                this.router.navigate([cname, 'licenseupdate']);
              }
            }
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000
            })
            Toast.fire({
              type: 'warning',
              title: this.getlogindata.message
            })
          }

        },
        (err) => {
          this.router.navigate(['']);
          this.ngxService.stop();
          if (err.status === 401) {
            Swal.fire({
              position: 'center',
              type: 'warning',
              title: 'Something Went Wrong',
              showConfirmButton: true
              //timer: 2000
            })
            this.router.navigate(['']);
          }
        }
      );
  }


  //Reset Password clicked
  SendOTP() {
    if (this.resetemailid == undefined || this.resetemailid == "") {
      this.emailvalid = true;
      this.toastr.warning("Enter EmailId", "", { timeOut: 2000 });
      return
    }
    else {
      var emailpattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (emailpattern.test(this.resetemailid) == false) {
        this.emailvalid = true;
        this.toastr.warning("Enter valid EmailId", "", { timeOut: 2000 });
        return
      }
    }

    if (this.emailvalid == true) {
      this.toastr.warning("Enter EmailId", "", { timeOut: 2000 });
      return
    }

    if (this.companyid == undefined || this.companyid == "0") {
      //$("#companypopup").modal('show');
      this.toastr.warning("Select a company.", "", { timeOut: 2000 });
      return;
    }
    this.ngxService.start();

    var Data = {
      //insert data from Frontend
      "emailid": this.resetemailid,
      "usertype": "company",
      "CompanyId": this.companyid
    };
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }
    let body = JSON.stringify(Data);//Convert frontend Data into Json
    this.http.post('smartboardApi/resetpasswordOTP', body, options).
      subscribe(
        (data) => {
          this.ngxService.stop();
          //console.log(data);
          
          this.getlogindata = data;
          if (this.getlogindata.status == true) {
            this.Resetbutton = true;
            this.localstorage.set('otpuserid', this.getlogindata.data.UserId);
            this.localstorage.set('otpuseremailid', this.getlogindata.data.EmailId);

            Swal.fire({
              position: 'center',
              type: 'success',
              text: this.getlogindata.message,
              showConfirmButton: true,
              timer: 3000
            })
            this.otpstatustext = "Your OTP Will Expire In:"
            this.otpstatus = true;
            var fiveMinutes = 60 * 10


            //startTimer(fiveMinutes, display);
            //startTimer(duration, display)
            var timer = fiveMinutes, minutes, seconds;
            // var myVar = setInterval(myTimer, 1000);


            var myVar = setInterval(function () {
              var display = document.querySelector('#time');
              var otptext = document.querySelector('#displaytimer');
              minutes = parseInt((timer / 60).toString(), 10)
              seconds = parseInt((timer % 60).toString(), 10);

              minutes = minutes < 10 ? "0" + minutes : minutes;
              seconds = seconds < 10 ? "0" + seconds : seconds;

              display.textContent = minutes + ":" + seconds;

              if (--timer < 0) {
                timer = fiveMinutes;
              }
              if (minutes == "00" && seconds == "00") {
                
                otptext.textContent = "The OTP has expired. Click on Resend OTP";
                display.textContent = "";
                clearInterval(myVar);
                stop();

              }
            }, 1000);
            if (minutes == "00" && seconds == "00") {
              
              this.otpstatustext = "The OTP has expired. Click on Resend OTP";
              this.otpstatus = false;
            }
            //this.router.navigate(['/super_admin/otp-verify']);
          }
          else {

            //this.router.navigate(['']);
            //this.close();
            Swal.fire({
              position: 'center',
              type: 'warning',
              text: this.getlogindata.message,
              showConfirmButton: true
              //timer: 2000
            })
            this.router.navigate(['/Login']);
            this.localstorage.remove('companyname');
            this.localstorage.remove('usertoken');
            this.localstorage.remove('companyid');
            this.localstorage.remove('Usertype');
            this.localstorage.remove('Logid');
          }

        },
        (err) => {
          this.ngxService.stop();
          if (err.status === 401) {
            Swal.fire({
              position: 'center',
              type: 'warning',
              title: 'Something went wrong',
              showConfirmButton: true
              //timer: 2000
            })
            this.router.navigate(['/Login']);
            this.localstorage.remove('companyname');
            this.localstorage.remove('usertoken');
            this.localstorage.remove('companyid');
            this.localstorage.remove('Usertype');
            this.localstorage.remove('Logid');
          }
        }
      );
  }

  ReSendOTP() {
    if (this.resetemailid == undefined || this.resetemailid == "") {
      this.emailvalid = true;
      this.toastr.warning("Enter EmailId", "", { timeOut: 2000 });
      return
    }
    else {
      var emailpattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (emailpattern.test(this.resetemailid) == false) {
        this.emailvalid = true;
        this.toastr.warning("Enter valid EmailId", "", { timeOut: 2000 });
        return
      }
    }

    if (this.emailvalid == true) {
      this.toastr.warning("Enter EmailId", "", { timeOut: 2000 });
      return
    }
    this.ngxService.start();

    var Data = {
      //insert data from Frontend
      "emailid": this.localstorage.get("otpuseremailid"),
      "usertype": "company",
      "userid": this.localstorage.get("otpuserid")
    };
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }
    let body = JSON.stringify(Data);//Convert frontend Data into Json
    this.http.post('smartboardApi/resendpasswordOTP', body, options).
      subscribe(
        (data) => {
          this.ngxService.stop();
          //console.log(data);
          
          this.getlogindata = data;
          if (this.getlogindata.status == true) {
            this.Resetbutton = true;
            this.localstorage.set('otpuserid', this.getlogindata.data.UserId);
            this.localstorage.set('otpuseremailid', this.getlogindata.data.EmailId);

            Swal.fire({
              position: 'center',
              type: 'success',
              text: this.getlogindata.message,
              showConfirmButton: true,
              timer: 3000
            })
            var fiveMinutes = 60 * 10


            //startTimer(fiveMinutes, display);
            //startTimer(duration, display)
            var timer = fiveMinutes, minutes, seconds;
            // var myVar = setInterval(myTimer, 1000);


            var myVar = setInterval(function () {
              var display = document.querySelector('#time');
              var otptext = document.querySelector('#displaytimer');
              otptext.textContent = "Your OTP Will Expire In: ";
              minutes = parseInt((timer / 60).toString(), 10)
              seconds = parseInt((timer % 60).toString(), 10);

              minutes = minutes < 10 ? "0" + minutes : minutes;
              seconds = seconds < 10 ? "0" + seconds : seconds;

              display.textContent = minutes + ":" + seconds;

              if (--timer < 0) {
                timer = fiveMinutes;
              }
              if (minutes == "00" && seconds == "00") {
                
                otptext.textContent = "The OTP has expired. Click on Resend OTP";
                display.textContent = "";
                clearInterval(myVar);
                stop();

              }
            }, 1000);
            if (minutes == "00" && seconds == "00") {
              
              this.otpstatustext = "The OTP has expired. Click on Resend OTP";
              this.otpstatus = false;
            }
            //this.router.navigate(['/super_admin/otp-verify']);
          }
          else {

            //this.router.navigate(['']);
            //this.close();
            Swal.fire({
              position: 'center',
              type: 'warning',
              text: this.getlogindata.message,
              showConfirmButton: true
              //timer: 2000
            })
            this.router.navigate(['/Login']);
            this.localstorage.remove('companyname');
            this.localstorage.remove('usertoken');
            this.localstorage.remove('companyid');
            this.localstorage.remove('Usertype');
            this.localstorage.remove('Logid');
          }

        },
        (err) => {
          this.ngxService.stop();
          if (err.status === 401) {
            Swal.fire({
              position: 'center',
              type: 'warning',
              title: 'Something went wrong',
              showConfirmButton: true
              //timer: 2000
            })
            this.router.navigate(['/Login']);
            this.localstorage.remove('companyname');
            this.localstorage.remove('usertoken');
            this.localstorage.remove('companyid');
            this.localstorage.remove('Usertype');
            this.localstorage.remove('Logid');
          }
        }
      );
  }
  //SendOTP() {
  //  if (this.resetemailid == "" || this.resetemailid == undefined) {
  //    this.toastr.info("Enter Emailid first", "", { timeOut: 2000 });
  //    return;


  //  }
  //}
  Verify() {

    if (this.otpdetail == undefined || this.otpdetail == "") {
      this.toastr.warning("Enter OTP", "", { timeOut: 2000 });
      return
    }
    var Data = {
      //insert data from Frontend
      "Verifyotp": this.otpdetail,
      "userid": this.localstorage.get('otpuserid'),
      "usertype": "Company",
      "CompanyId": this.companyid
    };


    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }
    let body = JSON.stringify(Data);//Convert frontend Data into Json
    this.http.post('smartboardApi/ValidateOTP', body, options).// API/Login iS the root Of Login.cs Page
      subscribe(
        (data) => {
          this.ngxService.stop();
          //console.log(data);
          
          this.getlogindata = data;
          if (this.getlogindata.status == true) {
            this.toastr.success(this.getlogindata.message, "", { timeOut: 2000 });

            this.validateotp = true;

          }
          else {

            Swal.fire({
              position: 'center',
              type: 'warning',
              text: this.getlogindata.message,
              showConfirmButton: true
              //timer: 2000
            })

            this.otpdetail = "";
            this.validateotp = false;

          }

        },
        (err) => {
          this.ngxService.stop();
          if (err.status === 401) {
            Swal.fire({
              position: 'center',
              type: 'warning',
              title: 'Something went wrong',
              showConfirmButton: true
              //timer: 2000
            })
            this.router.navigate(['/Login']);
            this.localstorage.remove('companyname');
            this.localstorage.remove('usertoken');
            this.localstorage.remove('companyid');
            this.localstorage.remove('Usertype');
            this.localstorage.remove('Logid');
          }
        }
      );
  }
  Resetpassword() {

    if (this.resetnewpwd == undefined || this.resetnewpwd == "") {
      this.toastr.warning("Enter new Password", "", { timeOut: 2000 });
      return;
    }
    if (this.newvalid == true) {
      this.toastr.warning("Invalid password", "", { timeOut: 2000 });
      return;
    }
    if (this.resetcnfpwd == undefined || this.resetcnfpwd == "") {
      this.toastr.warning("Enter confirm password", "", { timeOut: 2000 });
      return;
    }

    if (this.resetnewpwd != this.resetcnfpwd) {
      this.toastr.warning("Confirm password does not match to new password", "", { timeOut: 2000 });
      return;
    }


    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }
    var Data = {
      "newpassword": this.resetnewpwd,
      "userid": parseInt(this.localstorage.get('otpuserid')),
      "emailid": this.localstorage.get('otpuseremailid'),
      "CompanyId": this.companyid
    };

    
    let body = JSON.stringify(Data);
    this.ngxService.start();
    this.http.post('smartboardApi/savechangepassword', body, options).
      subscribe(
        (data) => {
          this.ngxService.stop();
          //console.log(data);
          
          this.getlogindata = data;
          if (this.getlogindata.status == true) {
            Swal.fire({
              position: 'center',
              type: 'success',
              text: this.getlogindata.message,
              showConfirmButton: false,
              timer: 2000
            })
            document.getElementById('login-admin').style.display = "block";
            document.getElementById('forget-section').style.display = "none";
            this.resetemailid = "";
            this.resetusername = "";
            //this.router.navigate(['/Login']);
            this.Resetbutton = false;
            this.validateotp = false;
          }
          else {
            document.getElementById('login-admin').style.display = "none";
            document.getElementById('forget-section').style.display = "block";
            Swal.fire({
              position: 'center',
              type: 'warning',
              text: this.getlogindata.message,
              showConfirmButton: false,
              timer: 2000
            })
          }

        },
        (err) => {
          
          this.ngxService.stop();
          if (err.status === 401 || err.status === 405) {
            Swal.fire({
              position: 'center',
              type: 'warning',
              text: "Session Expired login again",
              showConfirmButton: false,
              timer: 3000
            })
            this.localstorage.remove('companyname');
            this.localstorage.remove('usertoken');
            this.localstorage.remove('companyid');
            this.localstorage.remove('Usertype');
            this.localstorage.remove('Logid');
            this.router.navigate(['/Login']);

          }
          else {
            Swal.fire({
              position: 'center',
              type: 'warning',
              text: err.status,
              showConfirmButton: false,
              timer: 3000
            })
          }
        }
      );

  }
  changenewpassword(newpwd) {
    if (newpwd == undefined || newpwd == "") {
      this.newvalid = true;
    }
    else {
      var passwordpattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;

      if (passwordpattern.test(newpwd) == false || newpwd.length < 8) {

        this.newvalid = true;
      }
      else {
        this.newvalid = false;
      }
    }
  }
  passwordsymbol() {
    console.log('Madhu');
  }
  handleSuccess(ev) {
    this.successfullogin = ev;
  }
  handleLoad() { }

  handleExpire() { console.log('Expire Madhu'); }
  handleReset() { console.log('Reset Madhu'); }

  refreshlogin() {
    this.companyid = "0";
    this.username = undefined;
    this.password = undefined;
    this.resetemailid = "";
    this.usernamevalid = false
    this.passwordvalid = false;
    this.Resetbutton = false;
  }
  refresh() {
    this.usernamevalid = true;
    this.resetemailid = "";
    this.companyid = "0";
  }

}

@Directive({ selector: '[capsLock]' })
export class TrackCapsDirective {
  @Output('capsLock') capsLock = new EventEmitter<Boolean>();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }

}
